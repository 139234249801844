export * from './MOA'
export * from './PATIENT_SUPPORT_AND_DOSING'
export * from './EFFICACY'
export * from './SAFETY_PROFILE'
export * from './PATIENT_IMPACT'
export * from './SCREEN_OVERLAY'

export enum ContentTypes {
  MOA = 'MOA',
  PATIENT_SUPPORT_AND_DOSING = 'PATIENT_SUPPORT_AND_DOSING',
  EFFICACY = 'EFFICACY',
  SAFETY_PROFILE = 'SAFETY_PROFILE',
  PATIENT_IMPACT = 'PATIENT_IMPACT',
  SCREEN_OVERLAY = 'SCREEN_OVERLAY'
}

export const contentNavigation = [
  { title: 'MOA', instance: ContentTypes.MOA },
  { title: 'PATIENT_SUPPORT_AND_DOSING', instance: ContentTypes.PATIENT_SUPPORT_AND_DOSING },
  { title: 'EFFICACY', instance: ContentTypes.EFFICACY },
  { title: 'SAFETY_PROFILE', instance: ContentTypes.SAFETY_PROFILE },
  { title: 'PATIENT_IMPACT', instance: ContentTypes.PATIENT_IMPACT }
]
