import { contentUrl } from '../api'

export const PATIENT_IMPACT = {
  name: 'PATIENT_EXPERIENCE Screen',
  title: 'PATIENT_EXPERIENCE',
  mobileType: "mobile",
  overlay: {
    type: "ClickableImage",
    options: {
      src: `${contentUrl}/patient-impact/patientexperience_studydesigns_desktop@2x.png`,
      srcMobile: `${contentUrl}/patient-impact/patientexperience_studydesigns_mobile@2x.png`,
    },
  },
  content: [
    {
      menuTemplate: "VIDEO",
      menuName: "REAL PATIENT STORIES",
      subItems: [
        {
          subMenuName: "ANNIE",
          src: `${contentUrl}/patient_success_stories/AnnieSideBar.png`,
          srcSelected: `${contentUrl}/patient_success_stories/AnnieSideBar-selected.png`,
          contentTemplate: "VIDEO",
          content: {
            name: "<span>ANNIE | </span><i style='font-weight: normal'>High school student athlete,</i> Severe disease",
            subName: "ANNIE",
            src: `${contentUrl}/patient_success_stories/Annie/Annie-Vid1-Before.mp4`,
            text: "Follow Annie’s journey with DUPIXENT",
            poster: `${contentUrl}/patient_success_stories/patient-images/Annie/1.png`,
            timeLine: [
              { name: "Before", src: `${contentUrl}/patient_success_stories/Annie/Annie-Vid1-Before.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Annie/1.png` },
              { name: "Itch", src: `${contentUrl}/patient_success_stories/Annie/Annie-Vid2-Discovery.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Annie/2.png` },
              { name: "Skin", src: `${contentUrl}/patient_success_stories/Annie/Annie-Vid3-SkinAndItch.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Annie/3.png` },
              { name: "After", src: `${contentUrl}/patient_success_stories/Annie/Annie-Vid4-After.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Annie/4.png` },
              { name: "Safety", src: `${contentUrl}/patient_success_stories/Annie/Annie-Vid5-Safety.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Annie/5.png` },
            ],
          },
        },
        {
          subMenuName: "KRISTY",
          src: `${contentUrl}/patient_success_stories/KristySideBar.png`,
          srcSelected: `${contentUrl}/patient_success_stories/KristySideBar-selected.png`,
          contentTemplate: "VIDEO",
          content: {
            name: "<span>KRISTY | </span><i style='font-weight: normal'>Retiree,</i> Severe disease",
            subName: "KRISTY",
            src: `${contentUrl}/patient_success_stories/Kristy/Kristy-Vid1-Before.mp4`,
            text: "Follow Kristy's journey with DUPIXENT",
            poster: `${contentUrl}/patient_success_stories/patient-images/Kristy/1.png`,
            timeLine: [
              { name: "Before", src: `${contentUrl}/patient_success_stories/Kristy/Kristy-Vid1-Before.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Kristy/1.png` },
              { name: "Itch", src: `${contentUrl}/patient_success_stories/Kristy/Kristy-Vid2-Itch.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Kristy/2.png` },
              { name: "Skin", src: `${contentUrl}/patient_success_stories/Kristy/Kristy-Vid3-Skin.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Kristy/3.png` },
              { name: "After", src: `${contentUrl}/patient_success_stories/Kristy/Kristy-Vid4-After.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Kristy/4.png` },
              { name: "Safety", src: `${contentUrl}/patient_success_stories/Kristy/Kristy-Vid5-Safety.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Kristy/5.png` },
            ],
          },
        },
        {
          subMenuName: "RACHEL",
          src: `${contentUrl}/patient_success_stories/RachelSideBar.png`,
          srcSelected: `${contentUrl}/patient_success_stories/RachelSideBar-selected.png`,
          contentTemplate: "VIDEO",
          content: {
            name: "<span>RACHEL | </span><i style='font-weight: normal'>Nature lover,</i> Severe disease",
            subName: "RACHEL",
            src: `${contentUrl}/patient_success_stories/Rachel/Rachel-Vid1-Before.mp4`,
            text: "Follow Rachel's journey with DUPIXENT",
            poster: `${contentUrl}/patient_success_stories/patient-images/Rachel/1.png`,
            timeLine: [
              { name: "Before", src: `${contentUrl}/patient_success_stories/Rachel/Rachel-Vid1-Before.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Rachel/1.png` },
              { name: "Itch", src: `${contentUrl}/patient_success_stories/Rachel/Rachel-Vid2-Impact.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Rachel/2.png` },
              { name: "Skin", src: `${contentUrl}/patient_success_stories/Rachel/Rachel-Vid3-SkinAndItch.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Rachel/3.png` },
              { name: "After", src: `${contentUrl}/patient_success_stories/Rachel/Rachel-Vid4-After.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Rachel/4.png` },
              { name: "Safety", src: `${contentUrl}/patient_success_stories/Rachel/Rachel-Vid5-Safety.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Rachel/5.png` },
            ],
          },
        },
        {
          subMenuName: "BRITTON",
          src: `${contentUrl}/patient_success_stories/BrittonSideBar.png`,
          srcSelected: `${contentUrl}/patient_success_stories/BrittonSideBar-selected.png`,
          contentTemplate: "VIDEO",
          content: {
            name: "<span>BRITTON | </span><i style='font-weight: normal'>Avid hiker,</i> Moderate disease",
            subName: "BRITTON",
            src: `${contentUrl}/patient_success_stories/Britton/Britton-Vid1-Before.mp4`,
            text: "Follow Britton's journey with DUPIXENT",
            poster: `${contentUrl}/patient_success_stories/patient-images/Britton/1.png`,
            timeLine: [
              { name: "Before", src: `${contentUrl}/patient_success_stories/Britton/Britton-Vid1-Before.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Britton/1.png` },
              { name: "Itch", src: `${contentUrl}/patient_success_stories/Britton/Britton-Vid2-Itch.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Britton/2.png` },
              { name: "Skin", src: `${contentUrl}/patient_success_stories/Britton/Britton-Vid3-Skin.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Britton/3.png` },
              { name: "After", src: `${contentUrl}/patient_success_stories/Britton/Britton-Vid4-After.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Britton/4.png` },
              { name: "Safety", src: `${contentUrl}/patient_success_stories/Britton/Britton-Vid5-Safety.mp4`, poster: `${contentUrl}/patient_success_stories/patient-images/Britton/5.png` },
            ],
          },
        },
      ],
    },
    {
      menuTemplate: "SINGLE",
      menuName: "STUDY DESIGNS AND CLINICAL PROFILE",
      content: {
        contentTemplate: "IMAGE",
        content: {
          imageList: [{ src: `${contentUrl}/patient-impact/patientexperience_studydesigns_desktop@2x.png`, alt: "Patient Impact Study Design" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/patient-impact/patientexperience_studydesigns_mobile@2x.png`, alt: "Patient Impact Study Design" }],
        },
      },
    },
    {
      menuTemplate: "TEXT",
      menuName: "References",
      menuType: "REFERENCE",
      content: {
        contentTemplate: "IMAGE",
        content: {
          imageList: [{ src: `${contentUrl}/patient-impact/patientexperience_references_desktop@2x.png`, alt: "Patient Impact References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/patient-impact/patientexperience_references_mobile@2x.png`, alt: "Patient Impact References" }],
        },
      },
    },
  ],
}
