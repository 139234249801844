import { contentUrl } from '../api'

export const SCREEN_OVERLAY = {
  name: 'Trail Designs and Results',
  title: 'Trail Designs and Results',
  mobileType: "mobile",
  content: [
    {
      content: {
        contentTemplate: "IMAGE",
        content: {
          overlayMobileContent: true,
          imageList: [{ src: `${contentUrl}/patient-impact/patientexperience_studydesigns_desktop@2x.png`, alt: "Patient Impact References" }],
          mobileImageList: [{ srcMobile:  `${contentUrl}/patient-impact/patientexperience_studydesigns_mobile@2x.png`, alt: "Patient Impact References" }],
        },
      },
    },
  ],
}
