export const hasSeenFlyThrough = () => {
  try {
    const flyThroughSeen = localStorage.getItem('FlyThroughSeen')
    if (flyThroughSeen === 'true') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false
  }
}

export const setHasSeenFlyThrough = () => {
  try {
    localStorage.setItem('FlyThroughSeen', 'true')
  } catch (error) {}
}
