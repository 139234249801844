import { contentUrl } from '../api'

export const PATIENT_SUPPORT_AND_DOSING = {
  name: 'DUPIXENT_MYWAY_AND_DOSING Screen',
  title: 'PATIENT_SUPPORT_AND_DOSING',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: '<i>DUPIXENT MYWAY<sup>&reg;</sup></i> OVERVIEW',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/myway-dosing/myway_overview_desktop@2x.png`, alt: "MyWay Dosing Overview" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/myway-dosing/myway_overview_mobile@2x.png`, alt: "MyWay Dosing Overview" }],
        },
      },
    },
    {
      menuTemplate: "IMAGE",
      menuName: "<i>DUPIXENT MYWAY<sup>&reg;</sup></i>&nbsp;VIDEOS",
      multiVideosOnPage: false,
      subItems: [
        {
          subMenuName: `<span>Enrolling your patients in <i>DUPIXENT <span class="lowercase">MyWay</i>®</span></span>`,
          src: `${contentUrl}/my_way/Enrolling.png`,
          contentTemplate: "VIDEOFULLSCREEN",
          content: {
            name: "ENROLLING YOUR PATIENTS IN <i>DUPIXENT MyWay</i>®",
            src: `${contentUrl}/myway-dosing/asthma_destination_enrollment.mp4`,
            poster: `${contentUrl}/myway-dosing/myway_videos_enrolling@2x.png`,
            // transcript: {
            //   text: "See the full transcript here",
            //   link: "https://www.dupixenthcp.com/"
            // }
          },
        },
        {
          subMenuName: "How patients can access DUPIXENT",
          src: `${contentUrl}/my_way/How_to_access_video.png`,
          contentTemplate: "VIDEOFULLSCREEN",
          content: {
            name: "HOW PATIENTS CAN ACCESS DUPIXENT",
            src: `${contentUrl}/myway-dosing/asthma_destination_delivery.mp4`,
            poster: `${contentUrl}/myway-dosing/myway_videos_howpatients@2x.png`,
            // transcript: {
            //   text: "See the full transcript here",
            //   link: "https://www.dupixenthcp.com/"
            // }
          },
        },
      ],
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'DOSING INFORMATION',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/myway-dosing/myway_dosing_desktop@2x.png`, alt: "MyWay DOSING INFORMATION" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/myway-dosing/myway_dosing_mobile@2x.png`, alt: "MyWay DOSING INFORMATION" }],
        },
      },
    },
    {
      menuTemplate: 'TEXT',
      menuName: 'References',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/myway-dosing/myway_references_desktop@2x.png`, alt: "MyWay Dosing References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/myway-dosing/myway_references_mobile@2x.png`, alt: "MyWay Dosing References" }],
        },
      }
    },
  ],
}
