//@ts-nocheck
import React, { useState, useRef, Suspense, lazy, useEffect } from 'react'
import {
  Loading,
  ContentSidebar,
  MobileLandscapeOverlay,
  isMobile,
  StripNavigation,
  Alert,
  gaInitalise,
  Form,
  EventReducer,
  EventName,
  LeavingDisclaimer,
  styled,
  VideoFlyThrough
} from '@lxlabs/vc-components/dist/index'
import { useUiActions, useUiState } from './reducers/ui'

import { MOBILELANDSCAPEOVERLAY_CONTENT, CONTACTFORM_CONTENT, INTRO_CONTENT, LEAVING_DISCLAIMER } from './config/app'
import * as CONTENT from './config/content'
import { contentUrl } from './config/api'
import { ContentTypes } from './config/content'
import menu from './config/menu'
import showWarning from './utils/showWarning'
import { hasSeenFlyThrough, setHasSeenFlyThrough } from './utils/localStorage'

import BlackFade from './components/BlackFade'

import menuBg from './assets/images/menu-bg.png'
import logoSymbol from './assets/images/logo-symbol-white-small.png'
import menuBtnGreen from './assets/images/menu-burger.png'
import dupixentLogo from './assets/images/dupixent-logo.png'
import { uploadsUrl } from './config/api'

const AppScene = lazy(() => import('./AppScene'))
const { useEventActions, useEventState } = EventReducer

// REACT_APP_HOSTED_IFRAME_GA is used rather that GTMID as this is hosted within an iFrame
process.env.REACT_APP_HOSTED_IFRAME_GA && gaInitalise({ iframeHostedUrl: process.env.REACT_APP_HOSTED_IFRAME_GA })

function App() {
  const [content, setContent] = useState<ContentTypes | ''>('')
  const [showLoader, setShowLoader] = useState(true)
  const [blackFadeClass, setBlackFadeClass] = useState('hidden')
  const [leavingDisclaimer, setLeavingDisclaimer] = useState(null)
  const [showFlyThroughVideo, setShowFlyThroughVideo] = useState(!hasSeenFlyThrough());

  const [activeTab, setActiveTab] = useState<string>('')
  const uiState = useUiState()
  const uiSetters = useUiActions()
  const eventState = useEventState()
  const eventSetters = useEventActions()

  const jumpTimer = useRef(null)
  const fadeTimer = useRef(null)

  useEffect(() => {
    const scene = document.querySelector("[id^='aad-scene']")
    if (scene) {
      if (uiState.showPopup && isMobile) {
        scene.style.visibility = "hidden"
      } else {
        scene.style.visibility = "visible"
      }
    }

  }, [uiState.showPopup])

  useEffect(() => {
    document.addEventListener('click', (ev) => {
      if (ev.target.tagName === 'A') {
        ev.preventDefault()
        setLeavingDisclaimer({
          href: ev.target.href,
          text: ev.target.innerHTML,
        })
      }
    })
  }, [])

  useEffect(() => {
    if (!showFlyThroughVideo) {
        setHasSeenFlyThrough()
    }
  }, [showFlyThroughVideo])

  const onClose = () => {
    uiSetters.setShowPopup(false)
    fadeInOut(1200)
    clearTimeout(jumpTimer.current)
    jumpTimer.current = setTimeout(() => {
      eventSetters.setEvent(EventName.JUMP_TO_START)
    }, 600)
  }

  const showFade = () => {
    setBlackFadeClass('visible')
  }

  const hideFade = () => {
    setBlackFadeClass('click-through')
  }

  const fadeInOut = (time: number) => {
    showFade()
    clearInterval(fadeTimer.current)
    fadeTimer.current = setTimeout(() => {
      hideFade()
    }, time)
  }

  const onNavigationSelect = (instance: string) => {
    uiSetters.setShowForm(false)
    if (instance === 'default') {
      onClose()
    } else {
      uiSetters.setShowPopup(false)
      uiSetters.setAction('')
      fadeInOut(1200)

      clearTimeout(jumpTimer.current)
      jumpTimer.current = setTimeout(() => {
        eventSetters.setEvent(EventName.JUMP_TO_OBJECT, instance)
      }, 600)
    }
  }

  if (showFlyThroughVideo) {
    return <>
      <SkipFlyThrough onClick={() => setShowFlyThroughVideo(false)}>SKIP</SkipFlyThrough>
      <VideoFlyThrough
      src={`${uploadsUrl}/assets/videos/FlyThrough.mp4`}
      onEnd={() => {
        setShowFlyThroughVideo(false);
      }}/>
    </>
  }
  return (
    <>
      {isMobile && (
        <MobileLandscapeOverlay
          content={MOBILELANDSCAPEOVERLAY_CONTENT.overlayMessage}
          mobileLandscapeImage={`${contentUrl}/images/mobile-landscape-overlay.svg`}
        />
      )}
      {uiState.isReady && (
        <StripNavigation
          skewDegrees={'45deg'}
          navItems={menu.menuItems}
          onSelect={onNavigationSelect}
          logo={dupixentLogo}
          logoWidth={'160px'}
          menuBtn={menuBtnGreen}
          multipleLines={false}
          active={activeTab ? activeTab : 'default'}
          showForm={uiState.showForm}
          setShowForm={(show) => {
            if (show) {
              fadeInOut(600)
              setActiveTab('KEEP_IN_TOUCH')
            }
            setTimeout(() => {
              uiSetters.setShowForm(show)
            }, 600)
          }}
        />
      )}
      {uiState.showPopup && (
        <>
          <ContentSidebar contents={CONTENT[content as ContentTypes]} onClose={onClose} />
        </>
      )}

      {showLoader && <Loading isReady={uiState.isReady} content={INTRO_CONTENT} setShowLoader={setShowLoader} />}

      <BlackFade className={`${blackFadeClass}`} />
      {uiState.showForm && (
        <Form
          url={process.env.REACT_APP_CONTACT_US_ENDPOINT}
          content={CONTACTFORM_CONTENT}
          showForm={uiState.showForm}
          setShowForm={() => {
            eventSetters.setEvent(EventName.JUMP_TO_START)
            setTimeout(() => {
              uiSetters.setShowForm(false)
            }, 200)
          }}
        />
      )}

      <Suspense fallback={<></>}>
        <AppScene
          eventState={eventState}
          eventSetters={eventSetters}
          setActiveTab={setActiveTab}
          setContent={setContent}
          uiState={uiState}
          uiSetters={uiSetters}
        />
      </Suspense>
      <Alert
        message="For an optimal experience, please switch your browser to Google Chrome."
        closeText="OK"
        show={showWarning()}
        menuBg={menuBg}
        logoSymbol={logoSymbol}
      />
      {leavingDisclaimer && (
        <LeavingDisclaimer
          setShowModal={setLeavingDisclaimer}
          disclaimer={leavingDisclaimer}
          disclaimerCopy={LEAVING_DISCLAIMER}
        />
      )}
    </>
  )
}

const SkipFlyThrough = styled.p`
  bottom: 5%;
  color: white;
  font-size: 1.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out 0.75s;
  text-shadow: 0 0 4px black;
  z-index: 100;
  :hover {
    color: #f9f9f9;
    cursor: pointer;
    font-size: 1.3rem;
  }
`

export default App
