import { contentUrl } from '../api'

export const MOA = {
  name: 'DUPIXENT_IN_ACTION Screen',
  title: 'MOA',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'MOA VIDEO',
      content: {
        contentTemplate: "VIDEOFULLSCREEN",
        content: {
          name: "MOA Video",
          src: `${contentUrl}/moa/moa.mp4`,
          poster: `${contentUrl}/moa/moa_video_poster.png`,
          jobNumber: '',
          subtitles: {
            src: `${contentUrl}/components/captions1.vtt`,
            label: 'English',
            lang: 'en',
          },
        },
      },
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'DUPIXENT IN ACTION',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/moa/dupixentinaction_desktop_inaction@2x.png`, alt: "MOA Dupixent in action" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/moa/dupixentinaction_mobile_inaction@2x.png`, alt: "MOA Dupixent in action" }],
        },
      }
    },
    {
      menuTemplate: 'TEXT',
      menuName: 'References',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/moa/dupixentinaction_desktop_references@2x.png`, alt: "MOA References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/moa/dupixentinaction_mobile_references@2x.png`, alt: "MOA References" }],
        },
      }
    },
  ],
}
