import { contentUrl } from '../api'

export const EFFICACY = {
  name: 'EFFICACY Screen',
  title: 'EFFICACY',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'OVERVIEW',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/efficacy/efficacy_overview_desktop@2x.png`, alt: "Efficacy Overview" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/efficacy_overview_mobile@2x.png`, alt: "Efficacy Overview" }],
        },
      }
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'SKIN CLEARANCE',
      subItems: [
        {
          subMenuName: 'ADULTS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_skinclearance_adults_desktop@2x.png`, alt: "Skin Clearance Adults" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/efficacy_skinclearance_adults_mobile@2x.png`, alt: "Skin Clearance Adults" }],
          },
        },
        {
          subMenuName: 'CHILDREN',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_skinclearance_children_desktop@2x.png`, alt: "Skin Clearance Children" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/efficacy_skinclearance_children_mobile@2x.png`, alt: "Skin Clearance Children" }],
          },
        },
      ],
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'ITCH REDUCTION',
      subItems: [
        {
          subMenuName: 'ADULTS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_itchreduction_adults_desktop@2x.png`, alt: "Itch Reduction Adults" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/efficacy_itchreduction_adults_mobile@2x.png`, alt: "Itch Reduction Adults" }],
          },
        },
        {
          subMenuName: 'CHILDREN',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_itchreduction_children_desktop@2x.png`, alt: "Itch Reduction Children" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/efficacy_itchreduction_children_mobile@2x.png`, alt: "Itch Reduction Children" }],
          },
        },
      ],
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'REAL-WORLD DATA',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/efficacy/efficacy_realword_data_desktop@2x.png`, alt: "Real World Data" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/efficacy_realword_data_mobile@2x.png`, alt: "Real World Dat" }],
        },
      }
    },
    {
      menuTemplate: "MULTILEVELIMAGEDROPDOWN",
      menuName: "VISIBLE RESULTS",
      subItems: [
        {
          menuTemplate: 'IMAGE',
          subMenuName: 'ADULTS',
          subItems: [
            {
              subMenuName: "",
              src: `${contentUrl}/visible_results/AdultLegs.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/visible_results/mobile_images/AdultLegsBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/visible_results/mobile_images/AdultLegsAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
                title: "Adult patient 1 achieved a 4-point improvement in IGA",
                text: `<p>Actual patient treated with DUPIXENT. Not a clinical trial patient. Patient was on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/visible_results/AdultHand.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/visible_results/mobile_images/AdultHandBefore.png`, title: "BASELINE:", text: "IGA 3 <br />(moderate)" },
                after: { src: `${contentUrl}/visible_results/mobile_images/AdultHandAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
                title: "Adult patient 2 achieved a 3-point improvement in IGA",
                text: `<p>Actual patient treated with DUPIXENT. Not a clinical trial patient. Patient was on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/visible_results/AdultTorso.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/visible_results/mobile_images/AdultTorsoBefore.png`, title: "BASELINE:", text: "IGA 3 <br />(moderate)" },
                after: { src: `${contentUrl}/visible_results/mobile_images/AdultTorsoAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
                title: "Adult patient 3 achieved a 3-point improvement in IGA",
                text: `<p>Actual patient treated with DUPIXENT. Not a clinical trial patient. Patient was on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
          ],
        },
        {
          menuTemplate: "IMAGE",
          subMenuName: "ADOLESCENTS 12–17 YEARS OLD",
          subItems: [
            {
              subMenuName: "",
              src: `${contentUrl}/visible_results/Adolescent.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/visible_results/mobile_images/AdolescentArmBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/visible_results/mobile_images/AdolescentArmAfter.png`, title: "WEEK 16:", text: "IGA 2 <br />(mild)" },
                title: "Adolescent patient achieved a 2-point improvement in IGA",
                text: `<p>Actual 12-year-old patient in the Phase 3 adolescent DUPIXENT trial. Patient had a baseline IGA of 4 and EASI of 31. Individual results may vary.</p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p>This adolescent patient would not have met the primary endpoint in the clinical trial based on their IGA score at Week 16</p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
          ],
        },
        {
          menuTemplate: "IMAGE",
          subMenuName: "CHILDREN 6–11 YEARS OLD",
          subItems: [
            {
              subMenuName: "",
              src: `${contentUrl}/visible_results/ChildFace.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/visible_results/mobile_images/ChildFaceBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/visible_results/mobile_images/ChildFaceAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
                title: "Child patient 1 (6 years of age) achieved a 3-point improvement in IGA",
                text: `<p>Actual patient in a Phase 3 pediatric DUPIXENT trial (LIBERTY AD PEDS). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>^2</sup></p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/visible_results/ChildWrist.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/visible_results/mobile_images/ChildWristBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/visible_results/mobile_images/ChildWristAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
                title: "Child patient 2 (10 years of age) achieved a 3-point improvement in IGA",
                text: `<p>Actual patient in a Phase 3 pediatric DUPIXENT trial (LIBERTY AD PEDS). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>^2</sup></p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/visible_results/ChildFoot.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/visible_results/mobile_images/ChildFootBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/visible_results/mobile_images/ChildFootAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
                title: "Child patient 3 (9 years of age) achieved a 3-point improvement in IGA",
                text: `<p>Actual patient in a Phase 3 pediatric DUPIXENT trial (LIBERTY AD PEDS). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>^2</sup></p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/visible_results/ChildKnees.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/visible_results/mobile_images/ChildKneesBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/visible_results/mobile_images/ChildKneesAfter.png`, title: "WEEK 16:", text: "IGA 3 <br />(moderate)" },
                title: "Child patient 4 (6 years of age) achieved a 1-point improvement in IGA",
                text: `<p>Actual patient in a Phase 3 pediatric DUPIXENT trial (LIBERTY AD PEDS). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>^2</sup></p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
          ],
        },
      ]
    },
    {
      menuTemplate: 'TEXT',
      menuName: 'References',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/efficacy/efficacy_references_desktop@2x.png`, alt: "Efficacy References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/efficacy_references_mobile@2x.png`, alt: "Efficacy References" }],
        },
      }
    },
  ],
}
